// import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
// import { FlexSectionHeader } from '../style'

// import {
//   Button,
//   Dropdown,
//   List,
//   Menu,
//   Space,
//   Tag,
//   Typography,
//   Modal,
//   notification
// } from 'antd'
// import { useDispatch, useSelector } from 'react-redux'

// import { FileTextOutlined, PlusOutlined } from '@ant-design/icons'
// import { useHistory, useParams } from 'react-router'
// import { STUDENT, ADMIN } from '../../constants/userRoles'
// import {
//   deleteAssignment,
//   getAllAssignments
// } from '../../reducers/assignmentReducer'

// import axios from 'axios'
// import { DateTime } from 'luxon'
// import { Link } from 'react-router-dom'
// import useCoursePrivilege from '../../hooks/useCourseprivilege'

// const { Title, Text } = Typography

// const Schedulemeeting = () => {
//   const dispatch = useDispatch()
//   const { courseId } = useParams()
//   const history = useHistory()
//   const [lectures, allLectures] = useState([])
//   const { enrolled, privilege } = useCoursePrivilege()
//   const user = useSelector((state) => state.auth.user)
//   const assignments = useSelector((state) => state.assignments.data)

//   console.log('user', user)
//   console.log('assignments', assignments)
//   useEffect(() => {
//     ;(async () => {
//       const data = await axios.post(
//         `/meetings/${
//           privilege === STUDENT ? 'students-meetings' : 'instructor-meetings'
//         }`,
//         { id: user._id }
//       )
//       allLectures(data.data)
//     })()
//     // dispatch(getAllAssignments(courseId, privilege, user._id))
//   }, [courseId, privilege, user._id, dispatch])

//   return (
//     <>
//       <FlexSectionHeader>
//         <Title level={3}>All Meetings</Title>
//         {enrolled && privilege === ADMIN && (
//           <Button
//             onClick={() =>
//               history.push(`/app/course/${courseId}/schedulemeeting/create`)
//             }
//             type="dashed"
//             shape="round"
//             icon={<PlusOutlined />}
//           >
//             Add Meeting
//           </Button>
//         )}
//       </FlexSectionHeader>

//       <div style={{ marginTop: '16px' }}>
//         {lectures?.upcomingMeetings?.length > 0 && (
//           <List
//             dataSource={lectures.upcomingMeetings}
//             renderItem={(assignment) => (
//               <MeetingItem assignment={assignment} isMeetingUpcoming={true} />
//             )}
//           />
//         )}
//         <List
//           dataSource={lectures.previousMeetings}
//           renderItem={(assignment) => (
//             <MeetingItem assignment={assignment} isMeetingUpcoming={false} />
//           )}
//         />
//       </div>
//     </>
//   )
// }

// const StyledListItem = styled(List.Item)`
//   background-color: #fafafa;
//   padding: 24px 16px;
//   width: 100%;
//   margin-top: 16px;
//   border-radius: 10px;
//   // cursor: pointer;
//   &:hover {
//     // background-color: #d9d9d9;
//   }
// `
// const MeetingItem = ({ assignment, disabled, isMeetingUpcoming }) => {
//   const { courseId } = useParams()
//   const { privilege } = useCoursePrivilege()
//   const dispatch = useDispatch()

//   const history = useHistory()
//   const [isModalVisible, setIsModalVisible] = useState(false)
//   const [selectedMeeting, setSelectedMeeting] = useState(null)
//   const showModal = (meeting) => {
//     setSelectedMeeting(meeting)
//     setIsModalVisible(true)
//   }

//   const handleCancel = () => {
//     setIsModalVisible(false)
//     setSelectedMeeting(null)
//   }

//   const handleSubmit = async (meetingDetails) => {
//     console.log('meetingDetails', meetingDetails)
//     try {
//       let data = JSON.stringify({
//         id: meetingDetails._id
//       })
//       let config = {
//         method: 'delete',
//         maxBodyLength: Infinity,
//         url: '/meetings/delete-meetings',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         data: data
//       }
//       const resp = await axios.request(config)
//       notification.success({
//         message: 'Meeting Deleted!'
//       })
//     } catch (error) {
//       notification.error({
//         message: 'Error in Deleting meeting !'
//       })
//     }
//   }

//   const optionMenu = (
//     <Menu>
//       <Menu.Item>
//         <Title
//           style={{
//             margin: 0,
//             color: disabled ? '#a7a7a7d9' : 'initial' // Fixed typo 'intial' to 'initial'
//           }}
//           level={5}
//         >
//           <a href={assignment?.meetDetails?.meeting_url}>Meeting Link</a>
//         </Title>
//       </Menu.Item>
//       {assignment?.allRecordedUrls?.length > 0 && (
//         <Menu.Item>
//           <Title
//             style={{
//               margin: 0,
//               color: disabled ? '#a7a7a7d9' : 'initial' // Fixed typo 'intial' to 'initial'
//             }}
//             level={5}
//           >
//             <Button type="link" onClick={() => showModal(assignment)}>
//               Recordings
//             </Button>
//           </Title>
//         </Menu.Item>
//       )}
//       {privilege === ADMIN && (
//         <Menu.Item
//           danger
//           onClick={() => handleSubmit(assignment)} // dispatch(deleteAssignment(courseId, assignment.id))}
//         >
//           Delete
//         </Menu.Item>
//       )}
//     </Menu>
//   )

//   const getActions = (privilege) => {
//     return (
//       <>
//         <Space
//           onClick={(event) => {
//             event.stopPropagation()
//           }}
//         >
//           <Dropdown.Button
//             trigger={['click']}
//             placement="bottomLeft"
//             type="text"
//             overlay={optionMenu}
//           ></Dropdown.Button>
//         </Space>
//       </>
//     )
//   }

//   return (
//     <>
//       {assignment?.completed === false && (
//         <>
//           <StyledListItem
//             // onClick={() =>
//             //   history.push(`/app/course/${courseId}/assignment/${assignment.id}`)
//             // }
//             extra={getActions(privilege)}
//           >
//             <Space>
//               <FileTextOutlined
//                 style={{
//                   fontSize: '32px',
//                   color: disabled ? '#a7a7a7d9' : 'intial'
//                 }}
//               />
//               <Space size="medium" direction="vertical">
//                 <div style={{ display: 'flex', gap: '20px' }}>
//                   <Title
//                     style={{
//                       margin: 0,
//                       color: disabled ? '#a7a7a7d9' : 'intial'
//                     }}
//                     level={5}
//                   >
//                     {assignment?.meetDetails?.purpose}
//                   </Title>
//                   {/* {isMeetingUpcoming && ( */}

//                   {/* )} */}
//                 </div>{' '}
//                 <Space size="medium" direction="vertical">
//                   <div>
//                     {/* <Tag color={isMeetingUpcoming ? 'green' : 'red'}>
//                     {isMeetingUpcoming ? 'Upcoming' : 'Past'}
//                   </Tag> */}
//                     <Text type="secondary">
//                       {DateTime.fromISO(
//                         assignment?.meetDetails?.meetingTime
//                       ).toLocaleString(DateTime.DATETIME_FULL)}
//                     </Text>
//                   </div>
//                 </Space>
//                 <div>
//                   <Text
//                     // type="secondary"
//                     style={{
//                       margin: 0,

//                       color: disabled ? '#a7a7a7d9' : 'intial'
//                     }}
//                     level={5}
//                   >
//                     {assignment?.reccurrence === 'daily'
//                       ? 'Daily'
//                       : assignment?.reccurrence === 'weekly'
//                       ? 'Weekly'
//                       : assignment?.reccurrence === 'monthly'
//                       ? 'Monthly'
//                       : null}
//                   </Text>
//                   <Text
//                     // type="secondary"
//                     style={{
//                       margin: 10,
//                       color: disabled ? '#a7a7a7d9' : 'intial'
//                     }}
//                     level={5}
//                   >
//                     {assignment?.reccurrence === 'daily'
//                       ? assignment?.time
//                       : assignment?.reccurrence === 'weekly'
//                       ? assignment?.time
//                       : assignment?.reccurrence === 'monthly'
//                       ? assignment?.time
//                       : null}
//                   </Text>
//                 </div>
//               </Space>
//             </Space>
//           </StyledListItem>
//           <Modal
//             title="Recording Links"
//             visible={isModalVisible}
//             onCancel={handleCancel}
//             footer={null}
//           >
//             <List
//               dataSource={assignment?.allRecordedUrls || []}
//               renderItem={(item) => (
//                 <List.Item>
//                   <a href={item.url} target="_blank" rel="noopener noreferrer">
//                     Recording Link
//                   </a>
//                   <Text type="secondary" style={{ marginLeft: '10px' }}>
//                     {item.time}
//                   </Text>
//                 </List.Item>
//               )}
//             />
//           </Modal>
//         </>
//       )}
//     </>
//   )
// }

// export default Schedulemeeting
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexSectionHeader } from '../style'
import {
  Button,
  Dropdown,
  List,
  Menu,
  Space,
  Typography,
  Modal,
  notification,
  Row,
  Col,
  Card,
  Divider
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  FileTextOutlined,
  PlusOutlined,
  VideoCameraOutlined
} from '@ant-design/icons'
import { useHistory, useParams } from 'react-router'
import { STUDENT, ADMIN } from '../../constants/userRoles'
import {
  deleteAssignment,
  getAllAssignments
} from '../../reducers/assignmentReducer'
import axios from 'axios'
import { DateTime } from 'luxon'
import useCoursePrivilege from '../../hooks/useCourseprivilege'
import moment from 'moment'

const { Title, Text } = Typography

const Schedulemeeting = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const history = useHistory()
  const [lectures, setLectures] = useState([])
  const { enrolled, privilege } = useCoursePrivilege()
  const user = useSelector((state) => state.auth.user)

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const { data } = await axios.post(
          `/backend/meetings/${
            privilege === STUDENT ? 'students-meetings' : 'instructor-meetings'
          }`,
          { id: user._id, courseId: courseId }
        )
        setLectures(data)
      } catch (error) {
        notification.error({
          message: 'Error fetching meetings'
        })
      }
    }

    fetchMeetings()
    // Optionally, you can include dispatch(getAllAssignments(courseId, privilege, user._id)) if needed
  }, [courseId, privilege, user._id])

  return (
    <>
      <FlexSectionHeader>
        <Title level={3}>All Meetings</Title>
        {enrolled && privilege === ADMIN && (
          <Button
            onClick={() =>
              history.push(`/app/course/${courseId}/schedulemeeting/create`)
            }
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Add Meeting
          </Button>
        )}
      </FlexSectionHeader>

      <div style={{ marginTop: '16px' }}>
        {lectures?.upcomingMeetings?.length > 0 && (
          <List
            dataSource={lectures.upcomingMeetings}
            renderItem={(assignment) => (
              <MeetingItem
                key={assignment._id}
                assignment={assignment}
                isMeetingUpcoming={true}
              />
            )}
          />
        )}
        <List
          dataSource={lectures.previousMeetings}
          renderItem={(assignment) => (
            <MeetingItem
              key={assignment._id}
              assignment={assignment}
              isMeetingUpcoming={false}
            />
          )}
        />
      </div>
    </>
  )
}

const StyledListItem = styled(List.Item)`
  background-color: #fafafa;
  padding: 24px 16px;
  width: 100%;
  margin-top: 16px;
  border-radius: 10px;
`

const MeetingItem = ({ assignment, isMeetingUpcoming }) => {
  const { courseId } = useParams()
  const { privilege } = useCoursePrivilege()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const scheduleEntries = assignment?.schedule || []

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const handleDelete = async () => {
    try {
      await axios.delete('/backend/meetings/delete-meetings', {
        data: { id: assignment._id }
      })
      notification.success({ message: 'Meeting Deleted!' })
    } catch (error) {
      notification.error({ message: 'Error deleting meeting!' })
    }
  }

  const optionMenu = (
    <Menu>
      <Menu.Item>
        <Title style={{ margin: 0, color: 'initial' }} level={5}>
          <a href={assignment?.meetDetails?.meeting_url}>Meeting Link</a>
        </Title>
      </Menu.Item>
      {assignment?.allRecordedUrls?.length > 0 && (
        <Menu.Item>
          <Title style={{ margin: 0, color: 'initial' }} level={5}>
            <Button type="link" onClick={showModal}>
              Recordings
            </Button>
          </Title>
        </Menu.Item>
      )}
      {privilege === ADMIN && (
        <Menu.Item danger onClick={handleDelete}>
          Delete
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <>
      {!assignment?.completed && (
        <>
          <StyledListItem
          // extra={
          //   <Space>
          //     <Dropdown.Button
          //       trigger={['click']}
          //       placement="bottomLeft"
          //       type="text"
          //       overlay={optionMenu}
          //     />
          //   </Space>
          // }
          >
            {/* <Space>
              <FileTextOutlined style={{ fontSize: '32px' }} />
              <Space size="medium" direction="vertical">
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Title style={{ margin: 0 }} level={5}>
                    {assignment?.meetDetails?.purpose}
                  </Title>
                </div>
              </Space>
            </Space> */}
            <Card style={{ width: '100%', padding: '5px' }}>
              {' '}
              {/* Reduced padding */}
              <Space direction="vertical" style={{ width: '100%' }}>
                <div style={{ textAlign: 'center', marginBottom: '0px' }}>
                  {' '}
                  {/* Reduced margin */}
                  {/* <VideoCameraOutlined
                    style={{ fontSize: '24px', color: '#1890ff' }}
                  /> */}
                  <Title level={5} style={{ margin: '0px 0' }}>
                    {' '}
                    {/* Adjusted title level and margin */}
                    {assignment?.meetDetails?.purpose}
                  </Title>
                </div>
                <div
                  style={{
                    textAlign: 'center', // Center-aligns the text inside the div
                    margin: '0px 0' // Optional: Adds some margin around the title
                  }}
                >
                  <Title
                    level={5}
                    style={{
                      margin: 0,
                      color: 'initial' // Ensures the default color is used
                    }}
                  >
                    <a
                      href={assignment?.meetDetails?.meeting_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Meeting Link
                    </a>
                  </Title>
                </div>

                {assignment?.allRecordedUrls?.length > 0 && (
                  <div
                    style={{
                      textAlign: 'center', // Center-aligns the text inside the div
                      margin: '0px 0' // Optional: Adds some margin around the title
                    }}
                  >
                    <Title style={{ margin: 0, color: 'initial' }} level={5}>
                      <Button type="link" onClick={showModal}>
                        Recordings
                      </Button>
                    </Title>
                  </div>
                )}
                {privilege === ADMIN && (
                  <div
                    style={{
                      textAlign: 'center', // Center-aligns the text inside the div
                      margin: '0px 0' // Optional: Adds some margin around the title
                    }}
                  >
                    <Title style={{ margin: 0, color: 'initial' }} level={5}>
                      <Button type="link" onClick={handleDelete}>
                        Delete Meeting
                      </Button>
                    </Title>
                  </div>
                )}

                <Divider orientation="center">Schedule</Divider>
                {scheduleEntries.length > 0 ? (
                  <Row gutter={[16, 16]}>
                    {' '}
                    {/* Reduced gutter for tighter spacing */}
                    {scheduleEntries.map((entry, index) => (
                      <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Card
                          bordered={true}
                          style={{
                            textAlign: 'center',
                            padding: '8px', // Padding inside the card
                            borderRadius: '4px', // Rounded corners
                            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)', // Shadow effect
                            width: '200px', // Adjust width to reduce card size
                            margin: '0 auto' // Center align the card horizontally
                          }}
                        >
                          <Text
                            strong
                            style={{ display: 'block', marginBottom: '4px' }}
                          >
                            {entry.day}
                          </Text>
                          <Text style={{ fontSize: '14px' }}>
                            {moment(entry.time, 'HH:mm').format('hh:mm A')}
                          </Text>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Text
                    type="secondary"
                    style={{
                      textAlign: 'center',
                      display: 'block',
                      marginTop: '10px'
                    }}
                  >
                    No schedule available
                  </Text>
                )}
              </Space>
            </Card>
          </StyledListItem>
          <Modal
            title="Recording Links"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <List
              dataSource={assignment?.allRecordedUrls || []}
              renderItem={(item) => (
                <List.Item key={item.url}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    Recording Link
                  </a>
                  <Text type="secondary" style={{ marginLeft: '10px' }}>
                    {item.time}
                  </Text>
                </List.Item>
              )}
            />
          </Modal>
        </>
      )}
    </>
  )
}

export default Schedulemeeting
